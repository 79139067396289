import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/bookmyslots-logo.png';

const Footer = () => {
  return (
    <footer className="bg-white border-t border-gray-100">
      <div className="max-w-7xl mx-auto px-4">
        <div className="py-8">
          <div className="flex flex-col items-center space-y-6">
            {/* Logo */}
            <img src={logo} alt="BookMySlots.in" className="h-8 w-auto" />
            
            {/* Navigation Links */}
            <nav className="flex space-x-8">
              <Link
                to="/about"
                className="text-sm text-gray-600 hover:text-black transition-colors"
              >
                About
              </Link>
              <Link
                to="/blogs"
                className="text-sm text-gray-600 hover:text-black transition-colors"
              >
                Blogs
              </Link>
              <Link
                to="/contact"
                className="text-sm text-gray-600 hover:text-black transition-colors"
              >
                Contact
              </Link>
            </nav>

            {/* Copyright */}
            <div className="text-sm text-gray-500">
              © {new Date().getFullYear()} BookMySlot.in. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;