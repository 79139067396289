import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { FaEnvelope, FaLock, FaSpinner } from 'react-icons/fa';
import logo from '../assets/bookmyslots-logo.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    setError('');
    setIsLoading(true);

    try {
      const response = await fetch('/v1/authn/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include',
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error);
        return;
      }

      if (data.profilePic) {
        localStorage.setItem('profilePic', data.profilePic);
      }

      if (data.otp === 'pending') {
        navigate('/otp', { state: { next: '/' } });
        return;
      }

      window.location.href = '/';
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#fafafa] flex flex-col">
      <div className="flex-grow flex items-center justify-center px-4 min-h-screen">
        <div className="w-full max-w-md transform transition-all duration-300 ease-in-out translate-y-0 opacity-100">
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
            {/* Logo */}
            <div className="flex justify-center mb-8">
              <img 
                src={logo} 
                alt="BookMySlots Logo" 
                className="h-12 w-auto transform transition-all duration-300 ease-in-out hover:scale-105"
              />
            </div>

            <h2 className="text-2xl font-semibold text-center mb-2">Welcome Back</h2>
            <p className="text-gray-500 text-center mb-8">Sign in to your account</p>
            
            {error && (
              <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6 animate-bounce">
                {error}
              </div>
            )}

            <form onSubmit={onSubmitHandler} className="space-y-6">
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="relative group">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaEnvelope className="h-5 w-5 text-gray-400 group-hover:text-black transition-colors duration-200" />
                  </div>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full pl-10 px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200"
                    placeholder="Enter your email"
                    required
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="relative group">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaLock className="h-5 w-5 text-gray-400 group-hover:text-black transition-colors duration-200" />
                  </div>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full pl-10 px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200"
                    placeholder="Enter your password"
                    required
                  />
                </div>
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className="w-full py-2.5 bg-black text-white rounded-lg hover:bg-gray-900 transition-all duration-200 flex items-center justify-center transform hover:scale-[1.02]"
              >
                {isLoading ? (
                  <>
                    <FaSpinner className="animate-spin mr-2" />
                    Signing in...
                  </>
                ) : (
                  'Sign in'
                )}
              </button>
            </form>

            <div className="mt-6 space-y-2">
              <Link 
                to="/forget-password"
                className="block text-center text-sm text-gray-600 hover:text-black transition-colors"
              >
                Forgot your password?
              </Link>
              <p className="text-center text-sm text-gray-600">
                Don't have an account?{' '}
                <Link 
                  to="/signup" 
                  className="font-medium text-black hover:underline"
                >
                  Sign up
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;