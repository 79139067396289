import React, { useState } from 'react';
import { FaCopy, FaCheck, FaMapMarkerAlt, FaClock, FaPhone, FaUser, FaCity, FaMapPin, FaCalendar } from 'react-icons/fa';
const SlotCard = ({
  id,
  doctorName,
  address,
  city,
  pincode,
  locationLink,
  mobileNumber,
  fromTime,
  toTime,
  schedule,
}) => {
  const [copyStatus, setCopyStatus] = useState('');

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopyStatus('Copied');
    setTimeout(() => setCopyStatus(''), 3000);
  };

  const formatTime = (time) => {
    return new Date(`2000/01/01 ${time}`).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 transition-all duration-200 hover:shadow-md">
      {/* Doctor Info Section */}
      <div className="flex items-center gap-3 pb-4 border-b border-gray-100">
        <div className="h-10 w-10 rounded-full bg-gray-50 flex items-center justify-center">
          <FaUser className="h-5 w-5 text-gray-600" />
        </div>
        <h3 className="text-lg font-medium text-gray-900">Dr. {doctorName}</h3>
      </div>

      <div className="mt-4 space-y-4">
        {/* Contact */}
        <div className="flex items-center justify-between bg-gray-50 p-3 rounded-lg group">
          <div className="flex items-center space-x-3">
            <FaPhone className="h-4 w-4 text-gray-500 flex-shrink-0" />
            <span className="text-gray-700">{mobileNumber}</span>
          </div>
          <button
            onClick={() => handleCopy(mobileNumber)}
            className="p-2 hover:bg-gray-200 rounded-md transition-colors"
          >
            {copyStatus ? (
              <FaCheck className="h-4 w-4 text-green-500" />
            ) : (
              <FaCopy className="h-4 w-4 text-gray-400 group-hover:text-gray-600" />
            )}
          </button>
        </div>

        {/* Address */}
        <div className="bg-gray-50 p-3 rounded-lg space-y-3">
          <div className="flex items-start space-x-3">
            <FaMapMarkerAlt className="h-4 w-4 text-gray-500 mt-1 flex-shrink-0" />
            <span className="text-gray-700 break-words">{address}</span>
          </div>
        </div>

        {/* City & Pincode */}
        <div className="flex items-center justify-between bg-gray-50 p-3 rounded-lg">
          <div className="flex items-center space-x-3">
            <FaCity className="h-4 w-4 text-gray-500 flex-shrink-0" />
            <span className="text-gray-700">{city}</span>
          </div>
          <div className="flex items-center space-x-3">
            <FaMapPin className="h-4 w-4 text-gray-500 flex-shrink-0" />
            <span className="text-gray-700">{pincode}</span>
          </div>
        </div>

        {/* Time */}
        <div className="flex items-center justify-between bg-gray-50 p-3 rounded-lg">
          <div className="flex items-center space-x-3">
            <FaClock className="h-4 w-4 text-gray-500 flex-shrink-0" />
            <span className="text-gray-700">
              {formatTime(fromTime)} - {formatTime(toTime)}
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <FaCalendar className="h-4 w-4 text-gray-500 flex-shrink-0" />
            <span className="text-gray-700">{schedule}</span>
          </div>
        </div>
      </div>

      {/* Location Link */}
      {locationLink && (
        <div className="mt-6">
          <a
            href={locationLink}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center w-full py-2.5 bg-black text-white rounded-lg hover:bg-gray-900 transition-all duration-200 space-x-2"
          >
            <FaMapMarkerAlt className="h-4 w-4" />
            <span>View Location</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default SlotCard;
