import React, { useEffect, useState } from 'react';
import Header from "./Header";
import verifiedIcon from '../assets/verified_icon.png';
import { FaArrowRight, FaLink, FaCheck } from 'react-icons/fa';

const ProfileCard = ({ userId }) => {
  const [profilePic, setProfilePic] = useState('');
  const [rating, setRating] = useState(0.0);
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [aboutMe, setAboutMe] = useState('');
  const [speciality, setSpeciality] = useState('');
  const [verified, setVerified] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUserName, setLoggedInUserName] = useState('')
  const [loggedInUserProfilePic, setLoggedInUserProfilePic] = useState('')
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`/v1/profile/${userId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        setProfilePic(data.profilePic);
        setName(data.name);
        setGender(data.gender);
        setAboutMe(data.aboutMe);
        setSpeciality(data.speciality);
        setVerified(data.verified);
        setRating(data.rating);
        setIsLoggedIn(data.isLoggedIn);
        setLoggedInUserName(data.loggedInUserName)
        setLoggedInUserProfilePic(data.loggedInUserProfilePic)
      } catch (error) {
        window.location.href = '/';
      }
    };

    fetchUserData();
  }, [userId]);

  const handleLogout = async (e) => {
    e.preventDefault();
    const response = await fetch('/v1/logout');
    const data = response.json();
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    localStorage.removeItem('jwt');
    localStorage.removeItem('profilePic');

    setIsLoggedIn(false);
    setLoggedInUserName('');
    setLoggedInUserProfilePic('');
  };

  const handleSlotBooking = () => {
    window.location.href = `/slots/${userId}`;
  };

  const handleShare = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      })
      .catch((error) => error);
  };

  return (
    <div>
      <Header 
        profilePicture={loggedInUserProfilePic} 
        profileName={loggedInUserName} 
        isLoggedIn={isLoggedIn} 
        handleLogout={handleLogout}
      />
      <div className="pt-20 pb-2 px-4 bg-[#fafafa] min-h-screen flex items-center">
        <div className="max-w-xl mx-auto w-full">
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-8 relative">
            {/* Share Icon */}
            <button 
              onClick={handleShare}
              className="absolute top-4 right-4 p-2 rounded-full bg-gray-50 hover:bg-gray-100 transition-all duration-200 group"
            >
              <FaLink className="w-5 h-5 text-gray-600 group-hover:text-black transition-colors" />
            </button>

            {/* Show copied message */}
            {copied && (
              <div className="absolute top-4 right-16 flex items-center space-x-2 bg-black text-white text-sm px-3 py-1 rounded-full">
                <FaCheck className="w-3 h-3" />
                <span>Copied</span>
              </div>
            )}

            {/* Rest of the card content */}
            {/* Profile Header */}
            <div className="text-center mb-8">
              <span className="text-sm text-gray-600">Profile for <strong>BookMySlots.in</strong></span>
            </div>

            {/* Profile Picture Section */}
            <div className="relative flex flex-col items-center">
              <div className="relative">
                <img
                  src={`data:image/jpeg;base64,${profilePic}`}
                  alt="Profile"
                  className="w-32 h-32 rounded-full object-cover ring-4 ring-gray-50"
                />
                {verified && (
                  <div className="absolute -top-2 -right-2 group">
                    <img src={verifiedIcon} alt="Verified" className="w-6 h-6" />
                    <div className="absolute -top-2 left-full ml-2 hidden group-hover:block">
                      <div className="bg-black text-white text-xs px-2 py-1 rounded-md whitespace-nowrap">
                        Verified by BookMySlots.in
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Profile Details */}
            <div className="mt-8 space-y-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <div className="px-3 py-2 bg-gray-50 rounded-lg text-gray-900">Dr. {name}</div>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">About Me</label>
                <div className="px-3 py-2 bg-gray-50 rounded-lg text-gray-900 whitespace-pre-wrap">
                  {aboutMe}
                </div>
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Specialities</label>
                <div className="flex flex-wrap gap-2">
                  {speciality.split(',').map((item, index) => (
                    <span 
                      key={index}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-gray-100 text-gray-800"
                    >
                      {item.trim()}
                    </span>
                  ))}
                </div>
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Gender</label>
                <div className="flex gap-3">
                  <button
                    className={`flex-1 px-4 py-2 rounded-lg border text-sm font-medium transition-all duration-200 ${
                      gender === 'male'
                        ? 'bg-black text-white border-black'
                        : 'bg-gray-50 text-gray-700 border-gray-200'
                    }`}
                  >
                    Male
                  </button>
                  <button
                    className={`flex-1 px-4 py-2 rounded-lg border text-sm font-medium transition-all duration-200 ${
                      gender === 'female'
                        ? 'bg-black text-white border-black'
                        : 'bg-gray-50 text-gray-700 border-gray-200'
                    }`}
                  >
                    Female
                  </button>
                </div>
              </div>
            </div>

            {/* Book Appointment Button */}
            <button 
              onClick={handleSlotBooking}
              className="mt-8 w-full bg-black text-white px-4 py-3 rounded-lg hover:bg-gray-900 transition-colors duration-200 flex items-center justify-center group"
            >
              <span>Book an Appointment</span>
              <FaArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;