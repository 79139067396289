import React, { useState, useRef } from 'react';
import { FaFilter } from 'react-icons/fa';

const SearchFilter = ({
  cityList = [],
  specialityList = [],
  city,
  setCity,
  speciality,
  setSpeciality,
  pincode,
  setPincode,
  verified,
  setVerified,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);  // Filter toggle state
  const pincodeRef = useRef(null);

  const validatePincode = (value) => /^[1-9][0-9]{5}$/.test(value);

  const handlePincodeChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 6) {
      setPincode(value);
      if (pincodeRef.current) {
        pincodeRef.current.focus();
      }
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border mt-2 sm:mt-5 border-gray-100 sm:p-6">
      {/* Mobile Filter Button */}
      <div className={`sm:hidden ${isFilterOpen ? 'p-4' : ''}`}>
        <button
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-900 transition-colors"
        >
          <FaFilter
            className={`w-5 h-5 ${isFilterOpen ? 'text-green-400' : ''}`}
          />
          {isFilterOpen
            ? 'Hide Filters'
            : `Filters ${Object.values({ city, speciality, pincode, verified }).some(v => v) ? '(Active)' : ''}`}
        </button>

        {isFilterOpen && (
          <div className="mt-4">
            <div className="grid grid-cols-1 gap-4">
              {/* Speciality Select */}
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Speciality
                </label>
                <select
                  className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200"
                  value={specialityList.find((s) => s.id === speciality)?.name || ''}
                  onChange={(e) => {
                    const selected = specialityList.find(
                      (s) => s.name === e.target.value
                    );
                    setSpeciality(selected ? selected.id : '');
                  }}
                >
                  <option value="">Select Speciality</option>
                  {specialityList.map(({ id, name }) => (
                    <option key={id} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>

              {/* City Select */}
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  City
                </label>
                <select
                  className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200"
                  value={cityList.find((c) => c.id === city)?.name?.replace(/\s+/g, '-') || ''}
                  onChange={(e) => {
                    const selected = cityList.find(
                      (c) => c.name.replace(/\s+/g, '-') === e.target.value
                    );
                    setCity(selected ? selected.id : '');
                  }}
                >
                  <option value="">Select City</option>
                  {cityList.map(({ id, name }) => (
                    <option key={id} value={name.replace(/\s+/g, '-')}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Pincode Input */}
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Pincode
                </label>
                <input
                  ref={pincodeRef}
                  type="text"
                  inputMode="numeric"
                  pattern="\d*"
                  className={`w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200 ${
                    pincode === ''
                      ? 'border-gray-200'
                      : validatePincode(pincode)
                      ? 'border-green-500'
                      : 'border-red-500'
                  }`}
                  placeholder="Enter pincode"
                  value={pincode}
                  onChange={handlePincodeChange}
                />
              </div>

              {/* Verified Checkbox */}
              <div className="w-full flex items-center">
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    checked={verified}
                    onChange={(e) => setVerified(e.target.checked)}
                    className="w-4 h-4 rounded border-gray-300 text-green-500 focus:ring-green-500 checked:bg-green-500 checked:hover:bg-green-600 checked:focus:bg-green-500"
                  />
                  <span className="text-sm font-medium text-gray-700">
                    Verified Profiles Only
                  </span>
                </label>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Desktop View Filters (Always visible on larger screens) */}
      <div className="hidden sm:grid grid-cols-2 gap-4 p-4 sm:p-0">
        {/* Speciality Select */}
        <div className="w-full">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Speciality
          </label>
          <select
            className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200"
            value={specialityList.find((s) => s.id === speciality)?.name || ''}
            onChange={(e) => {
              const selected = specialityList.find(
                (s) => s.name === e.target.value
              );
              setSpeciality(selected ? selected.id : '');
            }}
          >
            <option value="">Select Speciality</option>
            {specialityList.map(({ id, name }) => (
              <option key={id} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>

        {/* City Select */}
        <div className="w-full">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            City
          </label>
          <select
            className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200"
            value={cityList.find((c) => c.id === city)?.name?.replace(/\s+/g, '-') || ''}
            onChange={(e) => {
              const selected = cityList.find(
                (c) => c.name.replace(/\s+/g, '-') === e.target.value
              );
              setCity(selected ? selected.id : '');
            }}
          >
            <option value="">Select City</option>
            {cityList.map(({ id, name }) => (
              <option key={id} value={name.replace(/\s+/g, '-')}>
                {name}
              </option>
            ))}
          </select>
        </div>

        {/* Pincode Input */}
        <div className="w-full">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Pincode
          </label>
          <input
            ref={pincodeRef}
            type="text"
            inputMode="numeric"
            pattern="\d*"
            className={`w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200 ${
              pincode === ''
                ? 'border-gray-200'
                : validatePincode(pincode)
                ? 'border-green-500'
                : 'border-red-500'
            }`}
            placeholder="Enter pincode"
            value={pincode}
            onChange={handlePincodeChange}
          />
        </div>

        {/* Verified Checkbox */}
        <div className="w-full flex items-center">
          <label className="flex items-center space-x-3">
            <input
              type="checkbox"
              checked={verified}
              onChange={(e) => setVerified(e.target.checked)}
              className="w-4 h-4 rounded border-gray-300 text-green-500 focus:ring-green-500 checked:bg-green-500 checked:hover:bg-green-600 checked:focus:bg-green-500"
            />
            <span className="text-sm font-medium text-gray-700">
              Verified Profiles Only
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
