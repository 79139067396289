import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

const SearchBar = ({ searchHandler }) => {
  const [query, setQuery] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    searchHandler(query);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  return (
    <div className="max-w-[800px] mx-auto w-full">
      <form 
        onSubmit={handleSearch} 
        className="bg-white rounded-xl shadow-sm border border-gray-100 p-6"
      >
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex-1">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Search by doctors name..."
              className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200 text-base"
            />
          </div>
          
          <button
            type="submit"
            className="px-6 py-2.5 bg-black text-white rounded-lg hover:bg-gray-900 transition-all duration-200 flex items-center justify-center gap-2 group"
          >
            <FaSearch className="w-4 h-4" />
            <span>Search</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;