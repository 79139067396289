import { useState, useEffect } from "react";
import ItemCard from '../components/ItemCard';
import SearchBar from '../components/SearchBar';
import Header from '../components/Header';
import SearchFilter from '../components/SearchFilter';
import Footer from "../components/Footer";

const Search = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [specialityList, setSpecialityList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [speciality, setSpeciality] = useState('');
  const [verified, setVerified] = useState(true);
  const [profileDetails, setProfileDetails] = useState({
    isLoggedIn: false
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0
  });
  const [loading, setLoading] = useState(false);

  const searchHandler = async (query) => {
    setLoading(true);
  
    try {
      const response = await fetch(`/v1/search?page=${pagination.currentPage}&limit=${15}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query, city, speciality, pincode, verified }),
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error('Site is facing some network issues');
      }
  
      const data = await response.json();
      setSpecialityList(data.specialityList);
      setCityList(data.cityList);
      setSearchResults(data.doctorsList);
      setProfileDetails({
        profilePicture: data.profilePicture,
        profileName: data.profileName,
        isLoggedIn: data.isLoggedIn
      });
      setPagination({
        currentPage: data.pagination.currentPage,
        totalPages: data.pagination.totalPages,
        totalItems: data.pagination.totalItems
      });
    } catch (error) {
      throw new Error('Error fetching search records')
    } finally {
      setLoading(false);
    }
  };  

  useEffect(() => {
    setLoading(true);
    const fetchInitialData = async () => {
      try {
        const response = await fetch(`/v1/search?page=${pagination.currentPage}&limit=${15}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ query: "", city: "", speciality: "", pincode: "", verified }),
        });

        if (!response.ok) {
          throw new Error('Site is facing some network issues');
        }

        const data = await response.json();
        setSpecialityList(data.specialityList);
        setCityList(data.cityList);
        setSearchResults(data.doctorsList);
        setProfileDetails({
          profilePicture: data.profilePicture,
          profileName: data.profileName,
          isLoggedIn: data.isLoggedIn
        });
        setPagination({
          currentPage: data.pagination.currentPage,
          totalPages: data.pagination.totalPages,
          totalItems: data.pagination.totalItems
        });
      } catch (error) {
        throw new Error('Error fetching search records')
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [pagination.currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        currentPage: newPage
      }));
    }
  };

  const logoutHandler = async () => {
    const response = await fetch('/v1/logout');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    localStorage.removeItem('jwt');
    localStorage.removeItem('profilePic');
    setProfileDetails({
      profilePicture: '',
      profileName: '',
      isLoggedIn: false
    });
  };
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header
        profilePicture={profileDetails.profilePicture}
        profileName={profileDetails.profileName}
        isLoggedIn={profileDetails.isLoggedIn}
        logoutHandler={logoutHandler}
      />

      <div className="flex-grow pt-20 pb-8"> {/* Added flex-grow and bottom padding */}
        <div className="flex flex-col justify-between items-center mt-5">
          <SearchBar 
            searchHandler={searchHandler}
            className="w-full max-w-4xl px-4 sm:px-6 md:px-8"
          /> 
          <SearchFilter
            cityList={cityList}
            specialityList={specialityList}
            city={city} 
            setCity={setCity} 
            speciality={speciality} 
            setSpeciality={setSpeciality} 
            pincode={pincode} 
            setPincode={setPincode}
            verified={verified}
            setVerified={setVerified} 
          />
        </div>

        <div className="flex-grow mt-6 ml-5 mr-5 mb-10 flex flex-col items-center space-y-6 px-4 sm:px-6 md:px-8 lg:px-12 min-h-[60vh]">
          {loading ? (
            <div className="flex justify-center items-center">
              <div className="animate-spin border-t-4 border-blue-500 border-solid rounded-full w-10 h-10"></div>
              <p className="ml-4 text-lg">Searching...</p>
            </div>
          ) : searchResults.length ? (
            searchResults.map((profile, index) => (
              <ItemCard key={index} {...profile} />
            ))
          ) : (
            <p className="text-center text-xl font-semibold text-gray-600">Oops! No results found...</p>
          )}
        </div>
        {/* Add this before the closing div of the search results container */}
        {searchResults.length > 0 && (
          <div className="flex justify-center items-center space-x-2 mt-8">
            <button
              className={`inline-flex items-center justify-center rounded-lg border border-gray-200 px-4 py-2 text-sm font-medium transition-colors
                ${pagination.currentPage === 1 
                  ? 'text-gray-400 cursor-not-allowed' 
                  : 'text-gray-700 hover:bg-gray-50'
                }`}
              onClick={() => handlePageChange(pagination.currentPage - 1)}
              disabled={pagination.currentPage === 1}
            >
              Previous
            </button>

            <div className="flex items-center space-x-2">
              {Array.from({ length: pagination.totalPages }, (_, index) => index + 1).map((page) => (
                <button
                  key={page}
                  className={`inline-flex h-9 w-9 items-center justify-center rounded-lg text-sm font-medium transition-colors
                    ${pagination.currentPage === page 
                      ? 'bg-black text-white' 
                      : 'text-gray-700 hover:bg-gray-50 border border-gray-200'
                    }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>

            <button
              className={`inline-flex items-center justify-center rounded-lg border border-gray-200 px-4 py-2 text-sm font-medium transition-colors
                ${pagination.currentPage === pagination.totalPages 
                  ? 'text-gray-400 cursor-not-allowed' 
                  : 'text-gray-700 hover:bg-gray-50'
                }`}
              onClick={() => handlePageChange(pagination.currentPage + 1)}
              disabled={pagination.currentPage === pagination.totalPages}
            >
              Next
            </button>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Search;
