import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { FaLock, FaSpinner } from 'react-icons/fa';
import logo from '../assets/bookmyslots-logo.png';

const ResetPassword = () => {
  const { resetId } = useParams();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [passwordRetype, setPasswordRetype] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResetIdValid, setIsResetIdValid] = useState(false);

  useEffect(() => {
    const validateResetId = async () => {
      try {
        const response = await fetch(`/v1/authn/reset-password/${resetId}`, {
          method: 'GET',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Invalid or expired reset link');
        }

        setIsResetIdValid(true);
      } catch (err) {
        setError(err.message || 'Failed to validate reset link');
        setIsResetIdValid(false);
      }
    };

    validateResetId();
  }, [resetId]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (password !== passwordRetype) {
      setError('Passwords do not match');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('/v1/authn/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ resetId, password, passwordRetype }),
        credentials: 'include',
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error || 'Failed to reset password');
        return;
      }

      setSuccess('Your password has been updated successfully!');
      setError('');
      setPassword('');
      setPasswordRetype('');

      setTimeout(() => navigate('/login'), 3000);
    } catch (err) {
      setError(err.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#fafafa] flex flex-col">
      <div className="flex-grow flex items-center justify-center px-4 min-h-screen">
        <div className="w-full max-w-md transform transition-all duration-300 ease-in-out translate-y-0 opacity-100">
          {isResetIdValid ? (
            <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              {/* Logo */}
              <div className="flex justify-center mb-8">
                <img 
                  src={logo} 
                  alt="BookMySlots Logo" 
                  className="h-12 w-auto transform transition-all duration-300 ease-in-out hover:scale-105"
                />
              </div>

              <h2 className="text-2xl font-semibold text-center mb-2">Reset Password</h2>
              <p className="text-gray-500 text-center mb-8">Enter your new password</p>

              {error && (
                <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6 animate-bounce">
                  {error}
                </div>
              )}

              {success && (
                <div className="bg-green-50 text-green-600 p-4 rounded-lg mb-6 transform transition-all duration-300 ease-in-out">
                  {success}
                </div>
              )}

              <form onSubmit={onSubmitHandler} className="space-y-6">
                {/* New Password Input */}
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">New Password</label>
                  <div className="relative group">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaLock className="h-5 w-5 text-gray-400 group-hover:text-black transition-colors duration-200" />
                    </div>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full pl-10 px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200"
                      placeholder="Enter new password"
                      required
                    />
                  </div>
                </div>

                {/* Confirm Password Input */}
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Confirm Password</label>
                  <div className="relative group">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaLock className="h-5 w-5 text-gray-400 group-hover:text-black transition-colors duration-200" />
                    </div>
                    <input
                      type="password"
                      value={passwordRetype}
                      onChange={(e) => setPasswordRetype(e.target.value)}
                      className="w-full pl-10 px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200"
                      placeholder="Confirm new password"
                      required
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full py-2.5 bg-black text-white rounded-lg hover:bg-gray-900 transition-all duration-200 flex items-center justify-center transform hover:scale-[1.02]"
                >
                  {isLoading ? (
                    <>
                      <FaSpinner className="animate-spin mr-2" />
                      Updating Password...
                    </>
                  ) : (
                    'Reset Password'
                  )}
                </button>
              </form>
            </div>
          ) : (
            <div className="bg-red-50 text-red-600 p-4 rounded-lg animate-bounce">
              {error || 'Validating reset link...'}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;