import React, { useEffect, useState } from 'react';
import verifiedIcon from '../assets/verified_icon.png'
import CreateSlotForm from './CreateSlotForm';
import Header from './Header';
import { FaCamera, FaPlus, FaUser, FaPhone, FaMapMarkerAlt, FaCity, FaMapPin, FaClock, FaCalendar } from 'react-icons/fa';


const ProfileForm = () => {
  const [userId, setUserId] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [type, setType] = useState('');
  const [verified, setVerified] = useState(false);
  const [aboutMe, setAboutMe] = useState('');
  const [speciality, setSpeciality] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [specialityList, setSpecialityList] = useState([]);
  const [slots, setSlots] = useState([]);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [error, setError] = useState('');
  const [initialData, setInitialData] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showSpecialityDropdown, setShowSpecialityDropdown] = useState(false);
  const [slotToEdit, setSlotToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); 

  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('/v1/profile');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (!data.isLoggedIn) {
          window.location.href = '/';
          return;
        }
        setUserId(data.userId);
        setProfilePic(data.profilePic);
        setName(data.name);
        setEmail(data.email);
        setGender(data.gender);
        setType(data.type);
        setAboutMe(data.aboutMe);
        setSpeciality(data.speciality);
        setVerified(data.verified)
        setInitialData({
          profilePic: data.profilePic,
          name: data.name,
          email: data.email,
          gender: data.gender,
          type: data.type,
          aboutMe: data.aboutMe,
          speciality: data.speciality,
        });
        setActiveTab(data.type === "doctor" ? 'slots' : 'upcoming');
        setCityList(data.cityList)
        setSlots(data.slots)
        setSpecialityList(data.specialityList)
      } catch (error) {
        window.location.href = '/';
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const hasChanges =
      name !== initialData.name ||
      email !== initialData.email ||
      gender !== initialData.gender ||
      type !== initialData.type ||
      aboutMe !== initialData.aboutMe ||
      speciality !== initialData.speciality ||
      profilePic !== initialData.profilePic;

    setIsSubmitDisabled(!hasChanges);
  }, [name, email, gender, type, aboutMe, speciality, profilePic, initialData]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];
        setProfilePic(base64String);
      };
      reader.readAsDataURL(file);
    } else {
      alert("File size should be less than 2MB");
    }
  };
  // Add this function to handle HTML content
  const formatAboutMe = (text) => {
    return text.replace(/\n/g, '<br>').replace(/\s\s+/g, ' ');
  };

  // Update the handleSubmit function to format aboutMe before sending
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const specialityIds = speciality.map((item) => item.id).join(", ");
    const updatedUser = {
      profilePic,
      name,
      email,
      gender,
      type,
      aboutMe: aboutMe,
      speciality: specialityIds,
    };

    const response = await fetch('/v1/update/profile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(updatedUser),
    });

    const data = await response.json();

    if (!response.ok) {
      setMessage('Failed to update the profile. Try again later.');
      setMessageType('error');
    } else {
      setMessage('Profile updated successfully!');
      setMessageType('success');
    }
    setIsLoading(false);

    // Clear the message after 2 seconds
    setTimeout(() => {
      setMessage('');
    }, 2000);

    if (data.profilePic) {
      localStorage.setItem('profilePic', data.profilePic);
      updatedUser.profilePic = data.profilePic;
    }
    setProfilePic(data.profilePic);
    setName(data.name);
    setEmail(data.email);
    setGender(data.gender);
    setType(data.type);
    setVerified(data.verified)
    setAboutMe(data.aboutMe);
    setInitialData({
      profilePic: data.profilePic,
      name: data.name,
      email: data.email,
      gender: data.gender,
      type: data.type,
      aboutMe: data.aboutMe,
      speciality: speciality,
      slots: slots,
    });
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      try {
        const response = await fetch(`/v1/delete-user/${userId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          alert(`Failed to delete account: ${errorData.error}`);
          return;
        }
  
        alert('Your account has been successfully deleted.');
        window.location.href = '/';
      } catch (error) {
        alert('An unexpected error occurred. Please try again later.');
      }
    }
  };
  

  const logoutHandler = async () => {
    const response = await fetch('/v1/logout');
    const data = response.json();
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    localStorage.removeItem('jwt');
    localStorage.removeItem('profilePic');

    window.location.href = '/';
  };

  const handleClosePopup = () => {
    setError('');
    setSlotToEdit(null);
    setIsPopupOpen(false);
  };

  const handleFormSubmit = async (slotFormData) => {
    const response = await fetch('/v1/slot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(slotFormData),
    });
  
    const data = await response.json();
    if (!response.ok) {
      setError(data.error);
    } else {
      const city = cityList.find(city => city.id === data?.cityId)?.name;
      const newSlot = {
        id:           data.id,
        name:         data.name,
        mobileNumber: data.mobileNumber,
        address:      data.address,
        locationUrl:  data.locationUrl,
        pincode:      data.pincode,
        cityId:       data.cityID,
        city:         city,
        schedule:     data.schedule,
        startTime:    data.startTime,
        endTime:      data.endTime,
      };
    
      setSlots((prevSlotList) => [...prevSlotList, newSlot]);
      handleClosePopup();
    }
  };
  

  const handleFormEdit = async (slotFormData) => {
    const response = await fetch('/v1/update-slot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(slotFormData),
    });
  
    const data = await response.json();
    if (!response.ok) {
      setError(data.error);
    } else {
      const updatedCity = cityList.find(city => city.id === slotFormData.city)?.name;
    
      if (updatedCity) {
        slotFormData.cityId = slotFormData.city;
        slotFormData.city = updatedCity;
      }

      setSlots((prevSlotList) => {
        return prevSlotList.map((slot) =>
          slot.id === slotFormData.id ? { ...slot, ...slotFormData } : slot
        );
      });
      handleClosePopup();
    }
  };

  // Toggle popup
  const toggleSlotPopup = () => setIsPopupOpen(!isPopupOpen);

  const handleSpecialitySelect = (specialityItem) => {
    // Check if the speciality is already selected
    if (!speciality.some((item) => item.id === specialityItem.id)) {
      const updatedSpecialities = [...speciality, specialityItem];
      setSpeciality(updatedSpecialities);
    }
  };
  
  // Helper function to remove a selected speciality (optional feature)
  const handleRemoveSpeciality = (specialityId) => {
    const updatedSpecialities = speciality.filter((item) => item.id !== specialityId);
    setSpeciality(updatedSpecialities);
  };

  const handleEditSlot = (slotId) => {
    const slot = slots.find((slot) => slot.id === slotId);
    if (slot) {
      setSlotToEdit(slot); // Pass the selected slot data
      setIsPopupOpen(true); // Open the popup
    }
  };
  
  const handleDeleteSlot = async (slotId) => {
    if (window.confirm('Are you sure you want to delete this slot?')) {
      try {
        const response = await fetch(`/v1/delete-slot/${slotId}`, { method: 'DELETE' });
        if (response.ok) {
          setSlots((prevSlots) => prevSlots.filter((slot) => slot.id !== slotId));
        } else {
          alert('Failed to delete slot.');
        }
      } catch (error) {
        alert('Failed to delete slot.');
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row w-full mt-3 mb-3 min-h-screen bg-gray-50/95">
      <Header
        profileName={name}
        profilePicture={profilePic}
        isLoggedIn={true}
        logoutHandler={logoutHandler}
      />

      {isPopupOpen && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40"></div>
      )}

      {isPopupOpen && (
        <CreateSlotForm
          onClose={handleClosePopup}
          onSubmit={handleFormSubmit}
          onEdit={handleFormEdit}
          cityList={cityList}
          slotData={slotToEdit}
          error={error}
        />
      )}

      <div className="flex flex-col md:flex-row flex-grow pt-20 px-4 md:px-8 gap-6">
        {/* Left Panel */}
        <div className="w-full md:w-1/3">
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Profile Picture Section */}
              <div className="relative group">
                <div className="relative mx-auto w-32 h-32">
                  <img
                    src={`data:image/jpeg;base64,${profilePic}`}
                    alt="Profile"
                    className="w-32 h-32 rounded-full object-cover ring-4 ring-gray-50"
                  />
                  <label className="absolute inset-0 flex items-center justify-center bg-black/40 rounded-full opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer">
                    <div className="text-white text-center">
                      <FaCamera className="w-6 h-6 mx-auto mb-1" />
                      <span className="text-sm">Change Photo</span>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="hidden"
                    />
                  </label>
                </div>

                {/* Verified Badge */}
                {type === 'doctor' && verified && (
                  <div className="absolute top-1 right-1">
                    <div className="relative group">
                      <img src={verifiedIcon} alt="Verified" className="w-6 h-6" />
                      <div className="absolute -top-2 left-full ml-2 hidden group-hover:block">
                        <div className="bg-black text-white text-xs px-2 py-1 rounded-md whitespace-nowrap">
                          Verified by BookMySlots.in
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Verification Banner */}
              {initialData.type === 'doctor' && !verified && (
                <div className="bg-amber-50 border border-amber-200 rounded-lg p-4 text-amber-800 text-sm">
                  <p className="text-center">
                    Send a picture of your blank prescription to{' '}
                    <span className="font-semibold">operation.bookmyslots@gmail.com</span>
                    {' '}from your registered email to get yourself verified.
                  </p>
                </div>
              )}

              {/* Form Fields */}
              <div className="space-y-4">
                <div className="space-y-2">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200"
                    placeholder="Enter your name"
                  />
                </div>

                <div className="space-y-2">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200"
                    placeholder="Enter your email"
                  />
                </div>

                {initialData.type === 'doctor' && (
                  <div className="space-y-2">
                    <label htmlFor="aboutMe" className="block text-sm font-medium text-gray-700">
                      About Me
                    </label>
                    <textarea
                      id="aboutMe"
                      value={aboutMe}
                      onChange={(e) => setAboutMe(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-200 h-32 resize-none whitespace-pre-wrap"
                      placeholder="Tell us about yourself"
                    />
                  </div>                
                )}
                {initialData.type === 'doctor' && (
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Specialties
                    </label>
                    <div className="relative">
                      <div className="flex flex-wrap gap-2 p-3 border border-gray-200 w-full rounded-lg bg-white min-h-[96px]">
                        {speciality.map((item) => (
                          <span
                            key={item.id}
                            className="inline-flex items-center px-3 py-1 h-8 rounded-md bg-gray-100 text-sm text-gray-700 group"
                          >
                            {item.name}
                            <button
                              onClick={() => handleRemoveSpeciality(item.id)}
                              className="ml-2 text-gray-400 hover:text-gray-600"
                            >
                              ×
                            </button>
                          </span>
                        ))}
                      </div>
                      <button
                        type="button"
                        onClick={() => setShowSpecialityDropdown(!showSpecialityDropdown)}
                        className="absolute right-2 top-2 w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
                      >
                        {showSpecialityDropdown ? '×' : '+'}
                      </button>
                      
                      {showSpecialityDropdown && (
                        <div className="absolute z-20 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg max-h-48 overflow-y-auto">
                          {specialityList.map((item) => (
                            <label
                              key={item.id}
                              className="flex items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
                            >
                              <input
                                type="checkbox"
                                checked={speciality.some(s => s.id === item.id)}
                                onChange={() => {
                                  if (speciality.some(s => s.id === item.id)) {
                                    handleRemoveSpeciality(item.id);
                                  } else {
                                    handleSpecialitySelect(item);
                                  }
                                }}
                                className="w-4 h-4 rounded border-gray-300 text-black focus:ring-black"
                              />
                              <span className="ml-3 text-sm text-gray-700">{item.name}</span>
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Gender
                  </label>
                  <div className="flex gap-3">
                    <button
                      type="button"
                      onClick={() => setGender('male')}
                      className={`flex-1 px-4 py-2 rounded-lg border text-sm font-medium transition-all duration-200 ${
                        gender === 'male'
                          ? 'bg-black text-white border-black'
                          : 'bg-white text-gray-700 border-gray-200 hover:bg-gray-50'
                      }`}
                    >
                      Male
                    </button>
                    <button
                      type="button"
                      onClick={() => setGender('female')}
                      className={`flex-1 px-4 py-2 rounded-lg border text-sm font-medium transition-all duration-200 ${
                        gender === 'female'
                          ? 'bg-black text-white border-black'
                          : 'bg-white text-gray-700 border-gray-200 hover:bg-gray-50'
                      }`}
                    >
                      Female
                    </button>
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Account Type
                  </label>
                  <div className="flex gap-3">
                    <button
                      type="button"
                      onClick={() => setType('user')}
                      className={`flex-1 px-4 py-2 rounded-lg border text-sm font-medium transition-all duration-200 ${
                        type === 'user'
                          ? 'bg-black text-white border-black'
                          : 'bg-white text-gray-700 border-gray-200 hover:bg-gray-50'
                      }`}
                    >
                      User
                    </button>
                    <button
                      type="button"
                      onClick={() => setType('doctor')}
                      className={`flex-1 px-4 py-2 rounded-lg border text-sm font-medium transition-all duration-200 ${
                        type === 'doctor'
                          ? 'bg-black text-white border-black'
                          : 'bg-white text-gray-700 border-gray-200 hover:bg-gray-50'
                      }`}
                    >
                      Doctor
                    </button>
                  </div>
                </div>
              </div>

              {/* Status Messages */}
              {message && (
                <div className={`text-sm p-3 rounded-lg ${
                  messageType === 'success' 
                    ? 'bg-green-50 text-green-800' 
                    : 'bg-red-50 text-red-800'
                }`}>
                  {message}
                </div>
              )}

              {/* Action Buttons */}
              <div className="space-y-3 pt-4">
                <button
                  type="submit"
                  disabled={isSubmitDisabled || isLoading}
                  className={`w-full py-2.5 px-4 rounded-lg text-sm font-medium transition-all duration-200 ${
                    isSubmitDisabled || isLoading
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-black text-white hover:bg-gray-900'
                  }`}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center">
                      <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                      Updating...
                    </div>
                  ) : 'Update Profile'}
                </button>

                <button
                  type="button"
                  onClick={handleDeleteAccount}
                  className="w-full py-2.5 px-4 rounded-lg text-sm font-medium bg-white text-red-600 border border-red-200 hover:bg-red-50 transition-colors duration-200"
                >
                  Delete Account
                </button>
              </div>
            </form>
          </div>
        </div>
        
        {/* Right side */}
        <div className="w-full md:w-2/3">
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
            {/* Tabs */}
            <div className="mb-4 flex space-x-6 border-b border-gray-200">
              {initialData?.type === 'doctor' && (
                <button
                  onClick={() => setActiveTab('slots')}
                  className={`px-4 py-2 transition-colors duration-200 ${
                    activeTab === 'slots' 
                      ? 'border-b-2 border-black text-black -mb-[2px]' 
                      : 'text-gray-600 hover:text-black'
                  }`}
                >
                  Slots
                </button>
              )}
              <button
                onClick={() => setActiveTab('upcoming')}
                className={`px-4 py-2 transition-colors duration-200 ${
                  activeTab === 'upcoming' 
                    ? 'border-b-2 border-black text-black -mb-[2px]' 
                    : 'text-gray-600 hover:text-black'
                }`}
              >
                Upcoming Bookings
              </button>
              <button
                onClick={() => setActiveTab('past')}
                className={`px-4 py-2 transition-colors duration-200 ${
                  activeTab === 'past'
                    ? 'border-b-2 border-black text-black -mb-[2px]'
                    : 'text-gray-600 hover:text-black'
                }`}
              >
                Past Bookings
              </button>
            </div>

            {/* Content area for tabs */}
            <div className="mt-4">
              {/* Create Slot Button moved here */}
              {initialData?.type === "doctor" && activeTab === 'slots' && (
                <div className="mb-6">
                  <button
                    type="button"
                    onClick={toggleSlotPopup}
                    className="flex items-center justify-center w-full py-2.5 px-4 rounded-lg text-sm font-medium bg-black text-white hover:bg-gray-900 transition-colors duration-200 group"
                  >
                    <FaPlus className="w-4 h-4 mr-2 text-white" />
                    Create a new Slot
                  </button>
                </div>
              )}
              
              <div className="p-4 rounded-lg border border-border bg-card text-card-foreground shadow-sm">
                {activeTab === 'slots' ? (
                  <div>
                    <h2 className="text-xl font-semibold mb-4">Active Slots</h2>
                    {slots && slots.length > 0 ? (
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
                        {slots.map((slot) => (
                          <div
                            key={slot.id}
                            className="relative flex flex-col border border-gray-200 rounded-lg bg-white p-6 shadow-sm transition-all duration-200 hover:shadow-md"
                          >
                            <div className="space-y-4">
                              {/* Title */}
                              <div className="flex items-center space-x-3">
                                <div className="h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center">
                                  <FaUser className="h-5 w-5 text-gray-600" />
                                </div>
                                <h3 className="text-lg font-semibold text-gray-900">{slot.name}</h3>
                              </div>

                              {/* Contact */}
                              <div className="flex items-center space-x-3 bg-gray-50 p-3 rounded-lg">
                                <FaPhone className="h-4 w-4 text-gray-500" />
                                <span className="text-gray-700">{slot.mobileNumber}</span>
                              </div>

                              {/* Address */}
                              <div className="bg-gray-50 p-3 rounded-lg space-y-2">
                                <div className="flex items-start space-x-2">
                                  <FaMapMarkerAlt className="h-4 w-4 text-gray-500 mt-1 flex-shrink-0" />
                                  <span className="text-gray-700 break-words flex-grow">{slot.address}</span>
                                </div>
                                <div className="flex justify-between">
                                  <div className="flex items-center space-x-2">
                                    <FaCity className="h-4 w-4 text-gray-500" />
                                    <span className="text-gray-700">{slot.city}</span>
                                  </div>
                                  <div className="flex items-center space-x-2">
                                    <FaMapPin className="h-4 w-4 text-gray-500" />
                                    <span className="text-gray-700">{slot.pincode}</span>
                                  </div>
                                </div>
                              </div>
                              {/* Schedule */}
                              <div className="bg-gray-50 p-3 rounded-lg">
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center space-x-3">
                                    <FaClock className="h-4 w-4 text-gray-500 flex-shrink-0" />
                                    <span className="text-gray-700">
                                      {new Date(`2000/01/01 ${slot.startTime}`).toLocaleTimeString('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true
                                      })}
                                      {' - '}
                                      {new Date(`2000/01/01 ${slot.endTime}`).toLocaleTimeString('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true
                                      })}
                                    </span>
                                  </div>
                                  <div className="flex items-center space-x-3">
                                    <FaCalendar className="h-4 w-4 text-gray-500 flex-shrink-0" />
                                    <span className="text-gray-700">{slot.schedule}</span>
                                  </div>
                                </div>
                              </div>
                              {/* Location Link */}
                              {slot.locationUrl && (
                                <a
                                  href={slot.locationUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="flex items-center justify-center space-x-2 p-2 bg-black rounded-lg text-white hover:bg-gray-900 transition-colors"
                                >
                                  <FaMapMarkerAlt className="h-4 w-4" />
                                  <span>View Location</span>
                                </a>
                              )}

                              {/* Actions */}
                              <div className="flex justify-end space-x-2 pt-4 border-t border-gray-100">
                                <button
                                  onClick={() => handleEditSlot(slot.id)}
                                  className="py-2.5 px-4 rounded-lg text-sm font-medium bg-black text-white hover:bg-gray-900 transition-colors"
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() => handleDeleteSlot(slot.id)}
                                  className="py-2.5 px-4 rounded-lg text-sm font-medium border border-red-200 text-red-600 hover:bg-red-50 transition-colors"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-muted-foreground">No slots available.</p>
                    )}
                  </div>
                ) : activeTab === 'upcoming' ? (
                  <div>
                    <h2 className="text-xl font-semibold mb-2">Upcoming Bookings</h2>
                    <p className="text-muted-foreground">No upcoming bookings.</p>
                  </div>
                ) : (
                  <div>
                    <h2 className="text-xl font-semibold mb-2">Past Bookings</h2>
                    <p className="text-muted-foreground">No past bookings.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;

