import React, { useState, useEffect } from "react";
import { FaSearch, FaArrowRight } from 'react-icons/fa';
import Header from "../components/Header";
import logo from '../assets/bookmyslots-logo.png';
import Footer from "../components/Footer";

const Home = () => {
  const [profileDetails, setProfileDetails] = useState({
    profileId: "",
    profilePicture: "",
    profileName: "",
    profileEmail: "",
    isLoggedIn: false
  });
  const [typedText, setTypedText] = useState('');
  const fullText = 'Find the right doctor and book appointments easily.';

  const handleClick = (e) => {
    e.preventDefault();
    window.location.href = '/search';
  }

  const handleLogout = async () => {
    const response = await fetch('/v1/logout');
    const data = await response.json();
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    localStorage.removeItem('jwt');
    localStorage.removeItem('profilePic');

    setProfileDetails({
      profileId: '',
      profilePicture: '',
      profileName: '',
      profileEmail: '',
      isLoggedIn: false
    });
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetch('/v1/profile');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setProfileDetails({
          profileId: data.userId,
          profilePicture: data.profilePic,
          profileName: data.name,
          profileEmail: data.email,
          isLoggedIn: data.isLoggedIn
        });
      } catch (error) {
        throw new Error('Error fetching profile data')
      }
    };

    fetchProfileData();
    setTypedText('');
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (typedText.length < fullText.length) {
        setTypedText(typedText + fullText.charAt(typedText.length));
      } else {
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [typedText]);
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Header 
        profilePicture={profileDetails.profilePicture} 
        profileName={profileDetails.profileName} 
        isLoggedIn={profileDetails.isLoggedIn} 
        logoutHandler={handleLogout}
      />

      <main className="flex-grow flex items-center justify-center px-4 min-h-screen">
        <div className="max-w-2xl w-full text-center">
          <div className="rounded-xl p-12">
            {/* Logo Section */}
            <div className="mb-8 transform hover:scale-105 transition-transform duration-200">
              <img src={logo} alt="Bookmyslots Logo" className="w-40 h-auto mx-auto" />
            </div>

            {/* Welcome Text */}
            <h1 className="text-2xl font-semibold text-gray-900 mb-4">
              Welcome to BookMySlots.in
            </h1>

            {/* Animated Text */}
            <p className="text-3xl font-bold text-black mb-8 min-h-[80px]">
              {typedText}
            </p>

            {/* Search Button */}
            <button
              onClick={handleClick}
              className="inline-flex items-center justify-center px-8 py-4 bg-black text-white rounded-lg hover:bg-gray-900 transition-all duration-200 group"
            >
              <span className="text-lg mr-2">Start Searching</span>
              <FaArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Home;