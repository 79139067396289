import React, { useState } from 'react';
import logo from '../assets/bookmyslots-logo.png';
import navLogo from '../assets/nav-logo.png';
import { FaHome, FaSearch, FaCog, FaSignOutAlt, FaUser } from 'react-icons/fa';

const Header = ({ profileName, profilePicture, isLoggedIn, logoutHandler, hideLogin = false }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const navigateTo = (path) => {
    window.location = path;
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-white border-b border-gray-100 z-50">
      <div className="sm:ml-5 sm:mr-5 px-4">
        <div className="flex h-20 items-center justify-between">
          {/* Left Side - Navigation */}
          <div className="relative">
            <button
              onClick={() => setShowMenu(!showMenu)}
              className="inline-flex items-center justify-center rounded-lg border border-gray-200 px-3 py-2 text-gray-900 hover:bg-gray-50 transition-colors"
            >
              <img src={navLogo} alt="Navigation" className="w-8 h-8 rounded-full" />
            </button>

            {showMenu && (
              <div className="absolute top-full left-0 mt-2 w-56 rounded-lg bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="py-1">
                  <button
                    onClick={() => navigateTo('/')}
                    className="flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
                  >
                    <FaHome className="mr-3 h-4 w-4" />
                    Home
                  </button>
                  <button
                    onClick={() => navigateTo('/search')}
                    className="flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
                  >
                    <FaSearch className="mr-3 h-4 w-4" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Right Side - Profile/Login */}
          {!hideLogin && (
            <div className="relative">
              {isLoggedIn ? (
                <button
                  onClick={() => setShowProfileMenu(!showProfileMenu)}
                  className="inline-flex items-center gap-2 rounded-lg border border-gray-200 px-3 py-2 hover:bg-gray-50 transition-colors"
                >
                  <img
                    src={profilePicture ? `data:image/jpeg;base64,${profilePicture}` : '../../assets/dummy-male.jpg'}
                    className="w-8 h-8 rounded-full object-cover"
                    alt={profileName}
                  />
                  <svg className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
              ) : (
                <div className="flex space-x-2">
                  <button
                    onClick={() => navigateTo('/login')}
                    className="inline-flex items-center justify-center h-10 rounded-lg bg-black px-4 text-sm font-medium text-white hover:bg-gray-900 transition-colors"
                  >
                    Login
                  </button>
                  <button
                    onClick={() => navigateTo('/signup')}
                    className="inline-flex items-center justify-center h-10 rounded-lg bg-black px-4 text-sm font-medium text-white hover:bg-gray-900 transition-colors"
                  >
                    Signup
                  </button>
                </div>
              )}
              {showProfileMenu && (
                <div className="absolute right-0 mt-2 w-56 rounded-lg bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <div className="px-4 py-2 text-sm text-gray-900 border-b border-gray-100">
                      <div className="font-medium">{profileName}</div>
                    </div>
                    <button
                      onClick={() => navigateTo('/profile')}
                      className="flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
                    >
                      <FaCog className="mr-3 h-4 w-4" />
                      Account Settings
                    </button>
                    <button
                      onClick={logoutHandler}
                      className="flex w-full items-center px-4 py-2 text-sm text-red-600 hover:bg-red-50 rounded-md"
                    >
                      <FaSignOutAlt className="mr-3 h-4 w-4" />
                      Sign Out
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;