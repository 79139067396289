import React from 'react';
import verifiedIcon from '../assets/verified_icon.png';
import { FaArrowRight, FaUser } from 'react-icons/fa';

const ItemCard = ({ id, profilePic, name, speciality, gender, rating, verified }) => {
  const handleProfileClick = () => {
    window.open(`/profile/${id}`, '_blank');
  };

  const handleSlotBooking = () => {
    window.open(`/slots/${id}`, '_blank');
  };

  const specialityList = speciality && speciality.trim() !== '' 
    ? speciality.split(',').map((item) => item.trim()) 
    : [];

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 transition-all duration-200 hover:shadow-md w-[340px] lg:w-[500px] relative"> {/* Added relative positioning */}
      {/* Verified Badge - Now positioned in top right corner */}
      {verified && (
        <div className="absolute top-4 right-4 group">
          <img
            src={verifiedIcon}
            alt="Verified Badge"
            className="w-5 h-5"
            title="Verified by BookMySlots.in"
          />
          <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block bg-black text-white text-xs rounded-lg py-1 px-2 z-10">
            Verified by BookMySlots.in
          </div>
        </div>
      )}

      <div className="flex flex-col space-y-6">
        {/* Header with Profile Picture and Verification */}
        <div className="flex items-start space-x-4">
          <div className="relative flex-shrink-0">
            {profilePic ? (
              <img
                src={`data:image/jpeg;base64,${profilePic}`}
                alt={`${name}'s profile`}
                className="w-16 h-16 rounded-full object-cover ring-2 ring-gray-50"
              />
            ) : (
              <div className="w-16 h-16 rounded-full bg-gray-100 flex items-center justify-center">
                <FaUser className="w-8 h-8 text-gray-400" />
              </div>
            )}
          </div>

          <div>
            <h2 className="text-lg font-semibold text-gray-900">Dr. {name}</h2>
            <p className="text-sm text-gray-600 capitalize">{gender}</p>
          </div>
        </div>
        {/* Specialities */}
        {specialityList.length > 0 && (
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-600 mb-2">Specialities</label>
            <div className="flex flex-wrap gap-2 max-w-full">
              {specialityList.map((spec, index) => (
                <span
                  key={index}
                  className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800 break-words"
                >
                  {spec}
                </span>
              ))}
            </div>
          </div>
        )}

        {/* Action Buttons */}
        <div className="flex gap-3 pt-2">
          <button
            onClick={handleProfileClick}
            className="flex-1 px-4 py-2 rounded-lg border text-sm font-medium transition-all duration-200 bg-white text-gray-700 border-gray-200 hover:bg-gray-50"
          >
            View Profile
          </button>
          <button 
            onClick={handleSlotBooking}
            className="flex-1 px-4 py-2 rounded-lg text-sm font-medium bg-black text-white hover:bg-gray-900 transition-all duration-200 flex items-center justify-center group"
          >
            Book
            <FaArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;