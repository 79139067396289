import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SlotCard from '../components/SlotCard';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaCalendar } from 'react-icons/fa';

const Slots = () => {
  const { userId } = useParams();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUserName, setLoggedInUserName] = useState('');
  const [loggedInUserProfilePic, setLoggedInUserProfilePic] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [doctorName, setDoctorName] = useState('');
  const [slotList, setSlotList] = useState([]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const logoutHandler = async () => {
    const response = await fetch('/v1/logout');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    localStorage.removeItem('jwt');
    localStorage.removeItem('profilePic');
    setIsLoggedIn(false);
    setLoggedInUserName('');
    setLoggedInUserProfilePic('');
  };

  useEffect(() => {
    const fetchSlots = async () => {
      const date = selectedDate.toISOString().split('T')[0];
      const response = await fetch(`/v1/slots/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ date }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setIsLoggedIn(data.isLoggedIn);
      setLoggedInUserName(data.loggedInUserName);
      setLoggedInUserProfilePic(data.loggedInUserProfilePic);
      setDoctorName(data.doctorName);
      setSlotList(data.slotList);
    };

    fetchSlots();
  }, [userId, selectedDate]);

  return (
    <div className="min-h-screen bg-[#fafafa]">
      <Header
        profilePicture={loggedInUserProfilePic}
        profileName={loggedInUserName}
        isLoggedIn={isLoggedIn}
        logoutHandler={logoutHandler}
      />

      <main className="container mx-auto px-4 pt-24 pb-12">
        <div className="flex flex-col lg:flex-row gap-8 min-h-[calc(100vh-200px)]">
          {/* Calendar Section */}
          <div className="w-full lg:w-1/3 flex items-center">
            <div className="w-full bg-white rounded-xl shadow-sm overflow-hidden">
              {/* Calendar Header */}
              <div className="bg-black text-white p-4 text-center">
                <h2 className="text-2xl font-semibold mb-2">
                  {selectedDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
                </h2>
                <p className="text-4xl font-bold">
                  {selectedDate.getDate()}
                </p>
                <p className="mt-1 text-gray-300">
                  {selectedDate.toLocaleDateString('en-US', { weekday: 'long' })}
                </p>
              </div>

              {/* Calendar Body */}
              <div className="p-6 text-center">
                <div className="bg-white rounded-lg">
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="MMMM d, yyyy"
                    className="hidden"
                    inline
                    calendarClassName="!border-0 !font-sans"
                    wrapperClassName="!block"
                  />
                </div>

                {/* Selected Date Info */}
                <div className="mt-6 pt-6 border-t border-gray-100">
                  <div className="flex items-center justify-between text-sm">
                    <span className="text-gray-600">Available Slots</span>
                    <span className="font-medium text-black">{slotList.length} slots</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Slots Section */}
          <div className="w-full lg:w-2/3">
            <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
              <div className="border-b pb-4 mb-6">
                <h2 className="text-xl font-semibold text-black">Available Slots</h2>
                <p className="mt-1 text-sm text-gray-600">
                  {slotList.length} slots available for {selectedDate.toLocaleDateString('en-US', { 
                    month: 'long', 
                    day: 'numeric', 
                    year: 'numeric' 
                  })}
                </p>
              </div>
              
              {slotList.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 auto-rows-fr">
                  {slotList.map((slot, index) => (
                    <SlotCard key={index} doctorName={doctorName} {...slot} />
                  ))}
                </div>
              ) : (
                <div className="text-center py-12">
                  <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-black mb-4">
                    <FaCalendar className="w-6 h-6 text-white" />
                  </div>
                  <p className="text-gray-600">No slots available for this date</p>
                  <p className="text-sm text-gray-500 mt-1">Try selecting a different date</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Slots;